import config from 'configuration';
import { mergeCKEditorToolbars, mergeConfiguration } from 'helper/mergeHelper';
import baseConfig from '../../../../legacy/customer/base/sco/baseConfiguration';

const brandadConfiguration = {
	global: {
		contentArea: '.struts-content-area',
		fullPageOnFirstClick: true,
	},
	SectionScrollHelper: {
		wofContainerOffset: {
			x: -12,
		},
		contentSelector: '#col3_content',
		arrowOffset: {
			x: -5,
		},
	},
	OverlayHelper: {
		overlayImageSource: context + '/page/style/img/lightbox_loader.gif',
		overlayImageCss: {
			width: '60px',
			height: '60px',
		},
	},
	AjaxRotator: {
		appendArea: 'body',
		progressMonitor: {
			percentageConf: {
				show: true,
				delimiter: '%',
				showTotal: false,
			},
			animationConf: {
				mode: 'custom',
				customAnimationConf: {
					loops: 5,
					steps: 4,
					offset: -3,
				},
			},
		},
	},
	EditViewStandard: {
		basScroll: {
			toggleControls: true,
		},
	},
	EditViewBanner: {
		basScroll: {
			toggleControls: true,
		},
	},
	slider: {
		playPause: false,
		fx: 'scrollLeft',
		barPosition: 'top',
		height: '500px',
		thumbnails: true,
		piePosition: 'rightTop',
		time: 4500,
		loaderColor: '#ffffff',
		loaderBgColor: '#6b6b6b',
	},
	/**
	 * CKEditor-Config
	 */
	CKEditorHandler: {
		toolbars: {
			toolbar_HtmlArea: [
				[
					'Bold', '-', 'Link', 'Unlink',
				],
			],
			toolbar_HtmlArea1: [
				[
					'Bold', 'Italic', 'Underline',
				], [
					'Link', 'Unlink',
				],
			],
			toolbar_HtmlArea1YT: [
				[
					'Bold', 'Italic', 'Underline',
				], [
					'Link', 'Unlink',
				], [
					'oembed', 'html5video', 'Image',
				],
			],
			toolbar_HtmlArea3: [
				[
					'Bold', 'Italic',
				], [
					'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
				], [
					'FontSize', 'SpecialChar',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',
				], [
					'Link', 'Unlink',
				],
			],
			toolbar_HtmlAreaNews: [
				[
					'Bold', 'Italic',
				], [
					'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
				], [
					'FontSize', 'SpecialChar',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',
				], [
					'Link', 'Unlink',
				],
			],
			toolbar_history: [
				[
					'FontSize',
				], [
					'Bold', 'Italic', 'Underline',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',
				], [
					'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
				], [
					'SpecialChar',
				],
			],
			toolbar_HtmlArea2: [
				[
					'Cut', 'Copy', 'PasteText', '-', 'Undo', 'Redo',
				], [
					'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
				], [
					'Link', 'Unlink', 'Anchor',
				], [
					'FontSize', 'SpecialChar', 'TextColor', 'BGColor',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter',
					'JustifyRight', 'JustifyBlock', '-',
				],
			],
			toolbar_HtmlArea2YT: [
				[
					'Cut', 'Copy', 'PasteText', '-', 'Undo', 'Redo',
				], [
					'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
				], [
					'Link', 'Unlink', 'Anchor',
				], [
					'FontSize', 'SpecialChar', 'TextColor', 'BGColor',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter',
					'JustifyRight', 'JustifyBlock', '-',
				], [
					'oembed', 'html5video', 'Image',
				],
			],
			toolbar_fliesstext: [
				[
					'Bold',
				], [
					'SpecialChar',
				],
			],
			toolbar_text: [
				['Bold', 'Italic'],
				['Subscript', 'Superscript'],
				['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'],
				['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
				['SpecialChar'],
			],
			toolbar_stoerer: [
				['Bold', 'Italic'],
				['Subscript', 'Superscript'],
				['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'],
				['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
				['FontSize'],
				['SpecialChar'],
			],
			toolbar_fliesstextFesteGroesse: [
				[
					'Bold',
				], [
					'SpecialChar',
				],
			],
			toolbar_textFesteGroesse: [
				[
					'Bold',
				], [
					'SpecialChar',
				],
			],
			toolbar_fliesstextWebTm: [
				[
					'Bold', 'Italic',
				], [
					'Subscript', 'Superscript',
				], [
					'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',
				], [
					'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
				], [
					'FontSize',
				], [
					'SpecialChar',
				],
			],
			toolbar_newslettertext: [
				[
					'Format',
				], [
					'Bold',
				], [
					'Subscript',
				],
			],
			toolbar_BoSupSub: [
				[
					'Bold',
				], [
					'Subscript', 'Superscript',
				], [
					'SpecialChar',
				],
			],
			toolbar_BoSupSubOlUl: [
				[
					'Bold',
				], [
					'Subscript', 'Superscript',
				], [
					'NumberedList', 'BulletedList',
				], [
					'SpecialChar',
				],
			],
		},
		config: {
			extraPlugins: 'colordialog,templatePlaceholder',
			format_p: {
				element: 'div',
			},
			removePlugins: 'liststyle',
			toolbarCanCollapse: true,
			dialog_backgroundCoverColor: 'rgb(0,0,0)',
			dialog_backgroundCoverOpacity: 0.8,
			dialog_buttonsOrder: 'rtl',
			dialog_noConfirmCancel: true,
			disableNativeSpellChecker: false,
		},
	},
	makeDatepicker: {
		showWeek: true,
		gotoCurrent: false,
		buttonImageOnly: true,
		selectOtherMonths: false,
		beforeShow: function (input, inst) {
			let trigger = inst.trigger,
				triggerElOffset = trigger.offset(),
				top = triggerElOffset.top - 1,
				triggerWidth = trigger.width(),
				left = triggerElOffset.left + triggerWidth + 4;

			if ($.browserDetection() == 'ie') {
				top = top - 2;
			}
			$.datepicker._pos = [
				left, top,
			];
		},
	},
	Dialog: {
		clipDefaultCloseButton: 'inner-top-right',
		shadow: false,
	},
	Lightbox: {
		imageMaxHeightFactor: 0.7,
		lightboxTriggerSelector: '.superviewButton > *',
		imageDisplayDelay: 1000,
		createLoupe: true,
		actionsPositionValue: '8%',
		lightboxOverlaySelector: '.overlay',
		lightboxContainerSelector: '.lightboxContainer',
		lightboxActionSelector: '.actions',
		lightboxResultSetSelector: 'div.imageList',
		lightboxLinkboxSelector: 'div.linkBox',
	},
	LightboxSuperview: {
		lightboxTriggerSelector: 'img#superviewimg',
		imageMaxHeightFactor: 0.7,
		imageDisplayDelay: 1000,
		createLoupe: true,
		loupePreviewSelector: '#superviewimg',
		loupeCenterInParent: true,
		actionsPositionValue: '8%',
		lightboxOverlaySelector: '.overlay',
		lightboxContainerSelector: '.lightboxContainer',
		lightboxActionSelector: '.actions',
		lightboxResultSetSelector: 'div.imageList',
		lightboxLinkboxSelector: 'div.linkBox',
	},
	BildDBLightbox: {
		lightboxTriggerSelector: '#pictureDbPreviewButton',
		lightboxResultSetSelector: 'div.imageList',
		descriptionTextSelector: '#imageDescriptionText',
		isBildDB: true,
		linkBoxLabelIsClickable: false,
		linkBoxHasControls: false,
		imageDisplayDelay: 1000,
		actionsPositionValue: '8%',
	},
	BannerLightbox: {
		closeButtonTemplate: '<div class="closeLightbox"><a class="icon"><div class="iconContainer"/></a></div>',
	},
	EditViewNewsletter: {
		previewSelector: '#newsletterPreview',
		dynamicZoomOptions: {
			previewSelector: '#newsletterPreview',
		},
		whiteSpaceOnSpecialChars: true,
		basScroll: {
			toggleControls: true,
		},
	},
	LightboxTabulation: {
		imageMaxHeightFactor: 0.7,
		imageDisplayDelay: 1000,
		createLoupe: true,
		actionsPositionValue: '8%',
		lightboxTriggerSelector: '.lightboxTrigger',
		lightboxOverlaySelector: '.overlay',
		lightboxResultSetSelector: '~ .imageList:first',
		lightboxActionSelector: '.actions',
		lightboxLinkboxSelector: 'div.linkBox',
		lightboxWrapperSelector: '.lightboxContainer',
		overlayContainerSelector: '~ .overlayContainer:first',
		lightboxContainerSelector: '.lightboxContainer',
	},
	ExternalRefresh: {
		reloadCode: 'doRefresh.mandateDownload',
	},
	SelectPreviewSwitch: {
		timeoutDuration: 50,
		wrapperSelector: 'div.portletInnerContainer',
		previewSelector: '.templatePreview img',
	},
	Panel: {
		initialState: 'closed',
		useLocalStorage: false,
		cssClass: {
			iconExpand: 'move_up_small',
			iconClosed: 'move_down_small',
		},
		selectors: {
			panelToggleIcon: '.toggleIcon',
		},
	},
	ReportListPanel: {
		useLocalStorage: false,
		cssClass: {
			iconExpand: 'move_up_small',
			iconClosed: 'move_down_small',
		},
	},
	MarketingKalender: {},
};

let configuration = mergeConfiguration(config, brandadConfiguration);
if (typeof brandadConfiguration.CKEditorHandler !== 'undefined' && //
	typeof brandadConfiguration.CKEditorHandler.toolbars !== 'undefined' && //
	typeof config.CKEditorHandler !== 'undefined' && //
	typeof config.CKEditorHandler.toolbars !== 'undefined') {
	configuration.CKEditorHandler.toolbars = mergeCKEditorToolbars(config.CKEditorHandler.toolbars, brandadConfiguration.CKEditorHandler.toolbars);
}

configuration = mergeConfiguration(configuration, baseConfig);
if (typeof configuration.CKEditorHandler !== 'undefined' && //
	typeof configuration.CKEditorHandler.toolbars !== 'undefined' && //
	typeof baseConfig.CKEditorHandler !== 'undefined' && //
	typeof baseConfig.CKEditorHandler.toolbars !== 'undefined') {
	configuration.CKEditorHandler.toolbars = mergeCKEditorToolbars(configuration.CKEditorHandler.toolbars, baseConfig.CKEditorHandler.toolbars);
}

export default configuration;
